exports.onRouteUpdate = ({ location }) => {
	window.onload = function (e) {
		let menu = document.getElementById("banner");
		let scrollOffset = 200;
		window.onscroll = () => {
			let windowOffset = window.scrollY;
			if (windowOffset > scrollOffset) {
				menu.classList.add("scrolled");
			} else {
				menu.classList.remove("scrolled");

			}
		}

		/* Video modal trigger click listener
		-----------------------------------------*/
		//declare an array
		var modalTriggers = ['trigger-project-one', 'trigger-project-two', 'trigger-project-three', 'trigger-project-four', 'trigger-project-five', 'trigger-project-six', 'trigger-project-seven', 'trigger-project-eight'];
		//loop through array and add event listener to for each
		modalTriggers.forEach(modalEvents);
		//function to add event listener to each item in array
		function modalEvents(item, index) {
			let triggers = document.querySelectorAll('.' + item);
			triggers.forEach(el => el.addEventListener('click', event => {

				// prevent default behavior for a-tags, button tags, etc.
				event.preventDefault();

				// add class to body to stop scroll
				document.body.classList.add("show-modal", "noscroll");
				
				let modalClass = item.replace("trigger-", "");
				let modal = document.querySelectorAll("."+modalClass);
				modal[0].classList.toggle("showModal");
			}));
		}

		let cloaseButtons = document.querySelectorAll('.close');
		cloaseButtons.forEach(el => el.addEventListener('click', event => {

			// prevent default behavior for a-tags, button tags, etc.
			event.preventDefault();

			// get open modals - there should only be one but we use querySelectorAll to get a NodeList as we don't have IDs
			let openModals = document.querySelectorAll('.showModal');	
			document.body.classList.remove("show-modal", "noscroll");
			openModals[0].classList.toggle("showModal");
		}));

	}(window, document)
}